<template>
    <div class="register page-center">
        <div class="web-content">
            <div class="process-structure">
                <div class="auth-process-body">
                    <div class="process-page-title-row">
                        <div class="process-page-title">
                            {{ $t('Register.Register.title') }}
                        </div>
                    </div>
                    <v-stepper
                        alt-labels
                        :value="nowStep"
                        class="stepper"
                    >
                        <v-stepper-header>
                            <v-stepper-step
                                :color="stepColor(1)"
                                :complete="nowStep > 1"
                                step="1"
                            >
                                {{ $t('Register.Register.stepper[0]') }}
                            </v-stepper-step>

                            <v-divider />

                            <v-stepper-step
                                :color="stepColor(2)"
                                :complete="nowStep > 2"
                                step="2"
                            >
                                {{ $t('Register.Register.stepper[1]') }}
                            </v-stepper-step>

                            <v-divider />

                            <v-stepper-step
                                :color="stepColor(3)"
                                step="3"
                            >
                                {{ $t('Register.Register.stepper[2]') }}
                            </v-stepper-step>
                        </v-stepper-header>
                    </v-stepper>
                    <!-- Child Router -->
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    data() {
        return {
        };
    },
    computed: {
        nowStep() {
            switch (this.$route.name) {
                case 'RegisterMemberData':
                    return 1;
                case 'RegisterEmailValidate':
                    return 2;
                case 'RegisterValidateSuccess':
                    return 3;
                default:
                    return 1;
            }
        },

    },
    created() { },
    mounted() {
    },
    methods: {
        stepColor(val) {
            if (this.nowStep >= val) {
                return '#F97E2B';
            }
            return '';
        },
    },
};
</script>

<style lang="scss">
.register {

}

</style>
